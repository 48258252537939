import { AxiosRequestConfig } from 'axios';

const endpoint = '/api/assets';

export default {
    getAssets: () => ({ method: 'GET', url: `${endpoint}` } as AxiosRequestConfig),
    getModelAssets: () => ({ method: 'GET', url: `${endpoint}/models` } as AxiosRequestConfig),
    getAcquiredAssets: () => ({ method: 'GET', url: `${endpoint}/acquired` } as AxiosRequestConfig),
    getAvailableAssets: () => ({ method: 'GET', url: `${endpoint}/available` } as AxiosRequestConfig),
    getAvailableDatasets: () => ({ method: 'GET', url: `${endpoint}/available/datasets` } as AxiosRequestConfig),
    getAvailableResults: () => ({ method: 'GET', url: `${endpoint}/available/results` } as AxiosRequestConfig),
    getAsset: (id: number) => ({ method: 'GET', url: `${endpoint}/${id}` } as AxiosRequestConfig),
    createAsset: (data: any) => ({ method: 'POST', url: `${endpoint}`, data } as AxiosRequestConfig),
    updateAsset: (id: number, data: any, strict?: boolean) =>
        ({
            method: 'PUT',
            url: `${endpoint}/${id}${strict === false ? `?strict=${strict}` : ''}`,
            data,
        } as AxiosRequestConfig),
    deleteAsset: (id: number) => ({ method: 'DELETE', url: `${endpoint}/${id}` } as AxiosRequestConfig),
    getAssetTypes: () => ({ method: 'GET', url: `${endpoint}/asset-types` } as AxiosRequestConfig),
    archiveAsset: (id: number) => ({ method: 'PUT', url: `${endpoint}/${id}/archive` } as AxiosRequestConfig),
    restoreArchivedAsset: (id: number) => ({ method: 'PUT', url: `${endpoint}/${id}/restore` } as AxiosRequestConfig),
    deleteDraftModelAsset: (id: string) => ({ method: 'DELETE', url: `${endpoint}/file/${id}` } as AxiosRequestConfig),
    getModelAssetDetails: (id: string) => ({ method: 'GET', url: `${endpoint}/${id}/details` } as AxiosRequestConfig),
};
